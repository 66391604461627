const setDrawer = (state, data) => {
    state.drawer = data;
};

const setNotificationCounter = (state, data) => {
    state.notificationCounter = data;
};

const setCompanyUpdate = (state, data) => {
    state.wasCompanyUpdated = data;
};

const setCompaniesSearchEntries = (state, data) => {
    state.companiesSearchEntries = data;
};

const setSettlementsSearchEntries = (state, data) => {
    state.settlementsSearchEntries = data;
};

const setCollectorsSearchEntries = (state, data) => {
    state.collectorsSearchEntries = data;
};

const setOtaSearchEntries = (state, data) => {
    state.collectorsSearchEntries = data;
};

const setFoodCompaniesSearchEntries = (state, data) => {
    state.collectorsSearchEntries = data;
};

const resetSearchStates = (state) => {
    state.companiesSearchEntries = [];
    state.settlementsSearchEntries = [];
    state.collectorsSearchEntries = [];
    state.otaSearchEntries = [];
    state.foodCompaniesSearchEntries = [];
};

export default {
    setDrawer,
    setNotificationCounter,
    setCompanyUpdate,
    setCompaniesSearchEntries,
    setSettlementsSearchEntries,
    setCollectorsSearchEntries,
    setOtaSearchEntries,
    setFoodCompaniesSearchEntries,
    resetSearchStates,
};
