import * as dayjs from "dayjs";
require('dayjs/locale/el')
import timezone from 'dayjs/plugin/timezone';


export default value => {
    if ( !value ) {
        return ""
    }
    return dayjs.utc(value).local().locale("el").format("dddd, DD MMMM YYYY");
};