import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Layout from "../layout/Layout.vue";
import Navbar from "../layout/Navbar.vue";
import Sidebar from "../layout/Sidebar.vue";

import Login from "../views/Auth/Login.vue";
import Forgot from "../views/Auth/Forgot.vue";
import Reset from "../views/Auth/Reset.vue";

/* Router Modules */
import reportsRoutes from "./modules/reports.js";
import adminRoutes from "./modules/admin.js";
import settlementRoutes from "./modules/settlements.js";
import companyRoutes from "./modules/company.js";
import collectorRoutes from "./modules/collector.js";
import libraryRoutes from "./modules/library.js";
import publicToolRoutes from "./modules/publicTool.js";
import emailsRoutes from "./modules/mailManagement.js";
import foodCompanyRoutes from "./modules/foodCompany.js";
import notFoundRoutes from "./modules/notFound.js";
import predefinedListRoutes from "./modules/predefinedLists.js";


Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            authentication: false,
        },
        beforeEnter(to, from, next){
            let isLoggedIn = store.getters["auth/isLoggedIn"];
            if (isLoggedIn) {
                next("/");
            } else next();
            
        }
    },
    {
        path: "/forgot-password",
        name: "Forgot",
        component: Forgot,
        meta: {
            authentication: false,
        },
    },
    {
        path: "/reset-password/:token",
        name: "Reset",
        component: Reset,
        meta: {
            authentication: false,
        },
        props: true,
    },
    {
        path: "/",
        name: "Home",
        component: Layout,
        redirect: "/landing",
        children: [
            {
                path: "/landing",
                name: "Landing",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "Landing" */ "../views/Home/Home.vue"
                        ),
                },
                meta: {
                    authentication: false,
                },
            },
            {
                path: "/notifications",
                name: "Notifications",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "Notifications" */ "../views/Notifications.vue"
                        ),
                },
                meta: {
                    authentication: false,
                },
                props: { main: true },
            },
            {
                path: "/notification-info/:id",
                name: "NotificationInfo",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "NotificationInfo" */ "../views/Home/NotificationInfo.vue"
                        ),
                },
                meta: {
                    authentication: false,
                },
                props: { main: true },
            },
            {
                path: "/register",
                name: "Register",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "Register" */ "../views/Auth/Register.vue"
                        ),
                },
                meta: {
                    authentication: false,
                },
            },
            {
                path: "/not-dangerous-register",
                name: "NotDangerousRegister",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "NotDangerousRegister" */ "../views/Auth/NotDangerousRegister.vue"
                        ),
                },
                meta: {
                    authentication: false,
                },
            },

            {
                path: "/account-activation",
                name: "AccountActivation",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "AccountActivation" */ "../views/Auth/AccountActivation.vue"
                        ),
                },
                meta: {
                    authentication: false,
                },
                props: { main: route => ({ id: route.query.id, token: route.query.token }) }
            },
            
            {
                path: "/ota",
                name: "Ota",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "Ota" */ "../views/Ota/Ota.vue"
                        ),
                },
                meta: {
                    authentication: true,
                },
            },
            
            {
                path: "/export-requests",
                name: "ExportRequests",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "ExportRequests" */ "../views/ExportRequests.vue"
                        ),
                },
                meta: {
                    authentication: true,
                },
            },

            {
                path: "/my-partners",
                name: "MyPartners",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "MyPartners" */ "../views/MyPartners.vue"
                        ),
                },
                meta: {
                    authentication: true,
                },
            },

            {
                path: "/e-paravolo",
                name: "EParavolo",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "EParavolo" */ "../views/EParavolo.vue"
                        ),
                },
                meta: {
                    authentication: true,
                },
            },

            {
                path: "/taxis-authenticate",
                name: "TaxisAuthentication",
                components: {
                    topbar: Navbar,
                    sidebar: Sidebar,
                    main: () =>
                        import(
                            /* webpackChunkName: "TaxisAuthentication" */ "../views/TaxisAuthentication.vue"
                        ),
                },
                meta: {
                    authentication: false,
                },
            },

            ...companyRoutes,

            ...settlementRoutes,

            ...collectorRoutes,

            ...reportsRoutes,

            ...adminRoutes,

            ...libraryRoutes,

            ...publicToolRoutes,

            ...emailsRoutes,

            ...foodCompanyRoutes,

            ...predefinedListRoutes
        ],
    },

    ...notFoundRoutes
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        return window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.authentication)) {
        if (store.getters["auth/getToken"]) {
            next();
        } else {
            return next("/login");
        }
    }
    next();
});

export default router;
