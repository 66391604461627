import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    drawer: true,
    notificationCounter: localStorage.getItem("hma_notification_counter") || null,
    wasCompanyUpdated: false,
    companiesSearchEntries: JSON.parse(localStorage.getItem("companies_search_entries")) || [],
    settlementsSearchEntries: JSON.parse(localStorage.getItem("settlements_search_entries")) || [],
    collectorsSearchEntries: JSON.parse(localStorage.getItem("collectors_search_entries")) || [],
    otaSearchEntries: JSON.parse(localStorage.getItem("ota_search_entries")) || [],
    foodCompaniesSearchEntries: JSON.parse(localStorage.getItem("food_companies_search_entries")) || [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
