export const CONFIG = {
    main_api: process.env.VUE_APP_MAIN_API,
    home_page: process.env.VUE_APP_HOME_URL,
    download_url: process.env.VUE_APP_PUBLIC_DOWNLOAD_URL,
    locale: process.env.VUE_APP_I18N_LOCALE,
    fallback_locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    table_items_per_page: 12,
    max_post_size: 110,
    analytics_id: process.env.VUE_APP_ANALYTICS_ID,
};

export default CONFIG;