<template>
    <v-btn
        block
        depressed
        large
        color="primary"
        type="submit"
        :disabled="isValid"
        :loading="loginStatus_Pending"
        @click.prevent="login()"
    >
        {{ $t("auth.login.loginButton") }}
    </v-btn>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { loginUser } from "@/api/authApi.js";
import { apiStatus } from  "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { apiObject } from "@/api/api";
import { mapState } from  "vuex";

import { MessageSuccess, MessageError } from "@/api/servises/ToastNotification";
export default {
    name:   "LoginButton",

    props: {
        isValid: {
            required: true,
            type: Boolean,
            default: true
        },
        username: {
            required: true,
            type: String
        },
        password: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            loginStatus: apiStatus.Idle
        }
    },

    computed: {
        ...apiStatusComputed("loginStatus"),
        ...mapState({
            user: state => state.auth.user
        })
    },

    methods: {
        async login() {
            this.loginStatus = apiStatus.Pending;
            const payload = {
                username: this.username,
                password: this.password
            };

            const { response, error } = await withAsync(loginUser, payload);

            if ( error ) {
                let errorMsg = error.response.data?.metadata?.response_message
                if(errorMsg) MessageError(error.response.data.metadata.response_message)
                this.loginStatus = apiStatus.Error;
                return;
            }

            
            let { id, name, token, roles, slug, userable_id, is_company, is_food_company, access } = response.data.data;

            const userInfo = {
                id,
                name,
                userable_id,
                is_company,
                is_food_company,
            };

            let [ role ] = slug;
            
            if (access) {
                this.$store.dispatch("auth/setUserInfo", userInfo);
                this.$store.dispatch("auth/setToken", token);
                this.$store.dispatch("auth/setUserRole", role);
                this.$router.push({name: "Home"});
                MessageSuccess(response.data.metadata.response_message)

                apiObject.defaults.headers["Authorization"] = `Bearer ${ token }`;
            }else {
                MessageError(this.$t(`messages.error.noAccess`))
            }

            this.loginStatus = apiStatus.Success;
        },
        
    },

    created() {
        this.apiStatus = apiStatus;
    },
}
</script>

<style lang="scss" scoped>

</style>