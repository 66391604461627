const setDrawer = ({ commit }, payload) => {
    commit("setDrawer", payload);
};

const setNotificationCounter = ({ commit }, payload) => {
    localStorage.setItem("hma_notification_counter", payload);
    commit("setNotificationCounter", payload);
};

const setCompanyUpdate = ({ commit }, payload) => {
    commit("setCompanyUpdate", payload);
};

const setCompaniesSearchEntries = ({ commit }, payload) => {
    localStorage.setItem("companies_search_entries", JSON.stringify(payload));
    commit("setCompaniesSearchEntries", payload);
};

const setSettlementsSearchEntries = ({ commit }, payload) => {
    localStorage.setItem("settlements_search_entries", JSON.stringify(payload));
    commit("setSettlementsSearchEntries", payload);
};

const setCollectorsSearchEntries = ({ commit }, payload) => {
    localStorage.setItem("collectors_search_entries", JSON.stringify(payload));
    commit("setCollectorsSearchEntries", payload);
};

const setOtaSearchEntries = ({ commit }, payload) => {
    localStorage.setItem("ota_search_entries", JSON.stringify(payload));
    commit("setCollectorsSearchEntries", payload);
};
const setFoodCompaniesSearchEntries = ({ commit }, payload) => {
    localStorage.setItem("food_companies_search_entries", JSON.stringify(payload));
    commit("setCollectorsSearchEntries", payload);
};

const resetSearchStates = ({ commit }) => {
    localStorage.removeItem("companies_search_entries");
    localStorage.removeItem("settlements_search_entries");
    localStorage.removeItem("collectors_search_entries");
    localStorage.removeItem("ota_search_entries");
    localStorage.removeItem("food_companies_search_entries");
    commit("resetSearchStates", []);
};

export default {
    setDrawer,
    setNotificationCounter,
    setCompanyUpdate,
    setCompaniesSearchEntries,
    setSettlementsSearchEntries,
    setCollectorsSearchEntries,
    setOtaSearchEntries,
    setFoodCompaniesSearchEntries,
    resetSearchStates,
};
