const getDrawer = (state) => state.drawer;

const getNotificationCounter = (state) => state.notificationCounter;

const getCopmanyUpdate = (state) => state.wasCompanyUpdated;

const getCompaniesSearchEntries = (state) => state.companiesSearchEntries;

const getSettlementsSearchEntries = (state) => state.settlementsSearchEntries;

const getCollectorsSearchEntries = (state) => state.collectorsSearchEntries;

const getOtaSearchEntries = (state) => state.otaSearchEntries;

const getFoodCompaniesSearchEntries = (state) => state.foodCompaniesSearchEntries;

export default {
    getDrawer,
    getNotificationCounter,
    getCopmanyUpdate,
    getCompaniesSearchEntries,
    getSettlementsSearchEntries,
    getCollectorsSearchEntries,
    getOtaSearchEntries,
    getFoodCompaniesSearchEntries,
};
